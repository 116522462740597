<template>
  <div class="modal edit" style="height: calc(100vh + 20px); top: -20px; background: #161616">
    <div class="modal__block">
      <div class="list__info modal-list" style="display:flex; align-items: flex-start; justify-content: space-between">
        <div>{{ $t('edit_clo_on') }} ID: {{ item.id }}</div>
        <Toggle
          v-if="newItem.clo_info.status !== 'EMPTY'"
          v-model="toggleValue"
          on-label="On"
          off-label="Off"
        />
      </div>
      <div
        v-if="isDev && newItem.clo_info.status !== 'EMPTY' && newItem.clo_info.clo_type !== null"
        >
        <p style="text-align: left; font-size: 12px;">{{ $t('type') }}: {{ newItem.clo_info.clo_type }}</p>
      </div>
      <div class="list__details" style="margin-top: 0px">
        <div
          class="input__block"
          v-if="isDev === true && newItem.clo_info.status === 'EMPTY'"
        >
          <p>{{ $t('choose_clo_type') }}</p>
          <multiselect
            autocomplete="off"
            class="multiselect__clo"
            v-model="newItem.clo_info.clo_type"
            :options="cloList"
            :searchable="true"
            :show-labels="false"
            :multiple="false"
            :allow-empty="true"
            placeholder="Clo"
          ></multiselect>
        </div>
        <div
          class="input__block ref"
          v-if="newItem.clo_info.status === 'EMPTY' && (!isDev || newItem.clo_info.clo_type === 'Keitaro')"
        >
          <p>{{ $t('reference_of_whit_page') }}</p>
          <input
            :class="{'error': isError}"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
            id="input"
            class="input"
            type="text"
            v-model="newUrl"
            placeholder="..."
          />
        </div>
        <div
          class="input__block"
          v-if="!isDev || newItem.clo_info.clo_type"
        >
          <p>{{ $t('choose_GEO') }}</p>
          <multiselect
            autocomplete="off"
            class="multiselect__clo"
            v-model="chooseGeo"
            :options="geoList"
            :searchable="true"
            :show-labels="false"
            multiple="true"
            track-by="name"
            label="name"
            :allow-empty="true"
            placeholder="GEO"
          ></multiselect>
        </div>
        <div
          class="input__block"
          v-if="!isDev || (newItem.clo_info.clo_type && newItem.clo_info.clo_type.toLowerCase() === 'Keitaro'.toLowerCase())"
        >
          <p>{{ $t('input_secure_key') }}</p>
          <input
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
            :class="{'error': isError}"
            id="input"
            class="input"
            type="text"
            v-model="newItem.clo_info.secure_key"
            placeholder="..."
          />
        </div>
      </div>
      <div class="btn__group" style="margin-top: 15px">
        <button
          @click="editClo"
        >
          <span v-if="newItem.clo_info.status === 'EMPTY'">Create</span>
          <span v-else>Save</span>

        </button>
        <button @click="cancel">
          {{ $t('back') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import Multiselect from "vue-multiselect";
import Toggle from '@vueform/toggle';

export default {
  name: 'modal-edit-clo',
  components: {Toggle, Multiselect},
  props: ['item', 'geoList'],
  data() {
    return {
      newUrl: null,
      isError: false,
      newItem: this.item,
      chooseGeo: [],
      toggleValue: true,
      cloList: ['Keitaro', 'Cloudflare'],
      cloItem: null,
      isDev: false,
    }
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    changeToggle() {
      let cloInfo = this.item.clo_info;

      this.toggleValue = cloInfo.status === 'ACTIVE' ? true : false;
    },
    parseGeo() {
      let geosString = this.newItem.clo_info;
      if (geosString.geos) {
        let geosArray = geosString.geos.split(',');
        let filteredArray = this.geoList.filter(obj => {
          return geosArray.includes(obj.key);
        });
        if (filteredArray) {
          this.chooseGeo = filteredArray;
        }
      }
    },
    editClo() {
      let geos = this.chooseGeo;
      let geoString = null;
      if (geos.length > 0) {
        geoString = '';
      }
      geos.forEach((item, index) => {
        let delineator = geos.length > 1 && index !== geos.length - 1
          ? ','
          : '';
        geoString += item.key + delineator;
      })

      if (!this.isDev
          && this.newItem.clo_info.clo_type
          && this.newItem.clo_info.clo_type.toLowerCase() === 'Keitaro'.toLowerCase()
          && this.newItem.clo_info.status === 'EMPTY'
          && !this.newUrl.includes('play.google.com/store/apps/details?id=')
        ) {
        document.querySelector('.ref').classList.add('error');
        setTimeout(function(){
          document.querySelector('.ref').classList.remove('error');
        }, 3000);
        return;
      }

      let secureKey = (this.newItem.clo_info.secure_key && this.newItem.clo_info.secure_key.length > 0) ? this.newItem.clo_info.secure_key : null;
      if (this.isDev) {
        if (this.newItem.clo_info.clo_type && this.newItem.clo_info.clo_type.toLowerCase() === 'Keitaro'.toLowerCase()) {
          if (this.newItem.clo_info.status === 'EMPTY') {
            window.Telegram.WebApp.sendData(`edit_link_create_keitaro_clo;${this.item.id};${secureKey};${this.newUrl};${geoString};${this.toggleValue}`);
            return;
          } else {
            window.Telegram.WebApp.sendData(`edit_link_save_keitaro_clo;${this.item.id};${secureKey};${geoString};${this.toggleValue}`);
          }
        } else {
          if (this.newItem.clo_info.status === 'EMPTY') {
            window.Telegram.WebApp.sendData(`edit_link_create_cloudflare_clo;${this.item.id};${this.newItem.choisedDomain.domain};${geoString};${this.toggleValue}`);
            return;
          } else {
            window.Telegram.WebApp.sendData(`edit_link_save_cloudflare_clo;${this.item.id};${geoString};${this.toggleValue}`);
          }
        }
      } else {
        if (this.newItem.clo_info.status === 'EMPTY') {
          window.Telegram.WebApp.sendData(`edit_link_create_clo;${this.item.id};${secureKey};${this.newUrl};${geoString};${this.toggleValue}`);
          return;
        } else {
          window.Telegram.WebApp.sendData(`edit_link_save_clo;${this.item.id};${secureKey};${geoString};${this.toggleValue}`);
        }
      }

      console.log('geoStr', geoString)
    }
  },
  created() {
    this.changeToggle();
    this.parseGeo();
  },
  beforeMount() {
    let urlParams = new URLSearchParams(window.location.search).get('is_dev');
    if (urlParams == 1) {
      this.isDev = true;
    }
  }
}
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss">
.toggle-container:focus {
  box-shadow: none!important;
}
.toggle-on {
  background: var(--toggle-bg-on, #09C200);
  border-color: var(--toggle-border-on, transparent);
  color: var(--toggle-text-on, #fff);
  justify-content: flex-start;
}

.toggle-handle,
.toggle-label {
  transition: 0s;
}

.toggle-off {
  background: var(--toggle-bg-on, #ff2a00);
  border-color: var(--toggle-border-on, transparent);
  color: var(--toggle-text-on, #fff);
}

.multiselect__clo {
  margin-top: 5px;
  border-radius: 10px;

  .multiselect {
    &__select {
      height: 40px;
      top: 0;
    }

    &__tag {
      background: #ff2a00!important;
    }

    &__content-wrapper {
      top: unset !important;
      max-height: 150px!important;
      height: 150px;
      min-height: 150px!important;
    }

    &__tags {
      input {
        height: 40px;
        background: transparent;
        color: white;
      }
    }

    &__tag-icon {
      &::after {
        color: white!important;
      }
    }
  }
}

</style>

<style lang="scss" scoped>
.input {
  width: 299px;
  overflow: scroll;
  white-space: nowrap;
  background: #242424;
  padding: 12px 13px;
  border-radius: 10px;
  margin-top: 5px;
  border: none;
  color: white;

  &__block {
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
  }
}

.modal-list {
  margin-top: -10px;
}

.error {
  border: 1px solid #ff2a00;
}
.example {
  font-size: 9px;
  margin-top: 5px;
  line-height: 12px;
  color: #d9d9d9;
}
</style>